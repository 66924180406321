/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * acumin-pro:
 *   - http://typekit.com/eulas/00000000000000003b9acafc
 *   - http://typekit.com/eulas/00000000000000003b9acaf2
 *   - http://typekit.com/eulas/00000000000000003b9acaf6
 * wayfinder-cf:
 *   - http://typekit.com/eulas/00000000000000007735fa11
 *
 * © 2009-2024 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2022-05-07 18:31:57 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=mai4fgw&ht=tk&f=26053.26055.26062.49468&a=1494256&app=typekit&e=css");

@font-face {
font-family:"acumin-pro";
src:url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"acumin-pro";
src:url("https://use.typekit.net/af/9871e5/00000000000000003b9acaf2/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3") format("woff2"),url("https://use.typekit.net/af/9871e5/00000000000000003b9acaf2/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3") format("woff"),url("https://use.typekit.net/af/9871e5/00000000000000003b9acaf2/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n2&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:200;font-stretch:normal;
}

@font-face {
font-family:"acumin-pro";
src:url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"wayfinder-cf";
src:url("https://use.typekit.net/af/d08650/00000000000000007735fa11/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff2"),url("https://use.typekit.net/af/d08650/00000000000000007735fa11/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff"),url("https://use.typekit.net/af/d08650/00000000000000007735fa11/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:100;font-stretch:normal;
}

.tk-acumin-pro { font-family: "acumin-pro",sans-serif; }
.tk-wayfinder-cf { font-family: "wayfinder-cf",serif; }